import React, { FC, useCallback, useMemo, useState } from 'react';
import { SalsifyData } from '@shared/types';
import useScreenRecognition from 'hooks/useScreenRecognition';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { NavigationOptions } from 'swiper/types/components/navigation';

import MainSlider from 'components/product/ProductCarousel/MainSlider';
import SecondarySlider from 'components/product/ProductCarousel/SecondarySlider';

import { LIMIT_FOR_CONTROLS } from './constants';
import { IPropsProductCarousel } from './models';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './ProductCarousel.scss';

SwiperCore.use([Navigation, Thumbs]);

const ProductCarousel: FC<IPropsProductCarousel> = ({
  items,
  defaultProductImage,
  isFirstViewport,
  globalData: { ariaLabelPrev, ariaLabelNext, dir },
  generalImageAlt,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  const { isMobile, isInit } = useScreenRecognition();
  const navPrevRef = React.useRef<HTMLButtonElement>(null);
  const navNextRef = React.useRef<HTMLButtonElement>(null);

  const productCarouselItems = useMemo(() => {
    const data: SalsifyData.ProductImage[] = [...(items?.length ? [...items] : [])];

    if (defaultProductImage?.desktopImage?.fallbackUrl) {
      data.unshift({
        image: { ...defaultProductImage },
        imageAlt: defaultProductImage?.altText || '',
      });
    }

    return data;
  }, [items, defaultProductImage]);

  const isSmallDevice = isMobile;

  const onBeforeInitHandler = useCallback((swiper: SwiperCore): void => {
    const navigation = swiper.params.navigation as NavigationOptions;
    navigation.prevEl = navPrevRef.current;
    navigation.nextEl = navNextRef.current;
  }, []);

  const onSwiperHandler = useCallback(
    (swiper: SwiperCore): void => {
      if (isInit) {
        return;
      }
      setThumbsSwiper(swiper);
    },
    [isInit]
  );

  const slidesInControls = useMemo(
    () => (isSmallDevice ? LIMIT_FOR_CONTROLS.mobile : LIMIT_FOR_CONTROLS.desktop),
    [isSmallDevice]
  );

  const isShowControls = useMemo(
    () => (isSmallDevice ? true : Number(productCarouselItems?.length) > slidesInControls),
    [isSmallDevice, slidesInControls, productCarouselItems?.length]
  );

  if (!productCarouselItems?.length) {
    return null;
  }

  return (
    <div data-testid="ProductCarousel" className="product-carousel">
      <MainSlider
        items={productCarouselItems}
        thumbsSwiper={thumbsSwiper}
        generalImageAlt={generalImageAlt}
      />
      {productCarouselItems.length > 1 ? (
        <SecondarySlider
          key={isInit ? 0 : 1}
          items={productCarouselItems}
          slidesInControls={slidesInControls}
          isFirstViewport={isFirstViewport}
          onSwiperHandler={onSwiperHandler}
          onBeforeInitHandler={onBeforeInitHandler}
          dir={dir}
          isSmallDevice={isSmallDevice}
          isShowControls={isShowControls}
          ariaLabelPrev={ariaLabelPrev}
          ariaLabelNext={ariaLabelNext}
          navPrevRef={navPrevRef}
          navNextRef={navNextRef}
          generalImageAlt={generalImageAlt}
        />
      ) : null}
    </div>
  );
};

export default ProductCarousel;
