import React, { FC } from 'react';
import classNames from 'classnames';

import { setInlineColorStyle } from 'components/atoms/ColorPicker/helpers';
import { UmbracoColorRules } from 'components/atoms/ColorPicker/models';
import Typography from 'components/elements/Typography';

import { IPropsReviewCard } from './models';

import './ReviewCard.scss';

const ProductCard: FC<IPropsReviewCard> = ({ data }) => {
  const { name, shortDescription, age, description, bgColor } = data;
  const colorData = bgColor?.[0]?.color;
  const label = colorData?.label;

  const conditionalInlineColor =
    colorData && setInlineColorStyle(colorData, UmbracoColorRules.backgroundColor);

  return (
    <article
      data-testid="ReviewCard"
      className={classNames('review-card', {
        [`review-card--bg-color-${label}`]: label,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...conditionalInlineColor}
    >
      <Typography customClass="review-card__name" data={name} />
      <div className="review-card__short-description-wrapper">
        <Typography customClass="review-card__short-description" data={shortDescription} />
        {age?.length ? <Typography customClass="review-card__age" data={age} /> : null}
      </div>
      {description?.length ? (
        <Typography customClass="review-card__description" data={description} />
      ) : null}
    </article>
  );
};
export default ProductCard;
