import React, { FC } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import DynamicImage from 'components/helpers/DynamicImage';

import { IPropsMainSlider } from './models';

import './MainSlider.scss';

const MainSlider: FC<IPropsMainSlider> = ({ items, thumbsSwiper, generalImageAlt }) => (
  <Swiper
    className={classNames(`main-slider`, {
      'single-item': items.length === 1,
    })}
    tag="section"
    wrapperTag="ul"
    spaceBetween={25}
    slidesPerView={1}
    thumbs={{ swiper: thumbsSwiper }}
    pagination={false}
    centeredSlides
  >
    {items.map((item, index) => {
      if (!item?.image?.desktopImage?.fallbackUrl) {
        return null;
      }

      const mainImage = item.image?.desktopImage.gatsbyImage.childImageSharp?.fluid;
      const aspectRatio = mainImage?.aspectRatio || 0;

      return (
        <SwiperSlide key={`${mainImage?.src || index}`} tag="li" data-index={index}>
          <div
            className={classNames('current-image-wrapper', {
              horizontal: aspectRatio > 1,
              vertical: aspectRatio < 1,
              'additional-image': index !== 0,
            })}
          >
            <DynamicImage
              image={item.image}
              objectFit="contain"
              isLazyLoading={index !== 0}
              customAlt={generalImageAlt}
            />
          </div>
        </SwiperSlide>
      );
    })}
  </Swiper>
);

export default MainSlider;
