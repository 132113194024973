import React, { FC } from 'react';

import Typography from 'components/elements/Typography';

import { IPropsSecondaryFeatures } from './models';

import './SecondaryFeatures.scss';

const SecondaryFeatures: FC<IPropsSecondaryFeatures> = ({ data }) => {
  const [{ items }] = data;

  return (
    <ul data-testid="SecondaryFeatures" className="secondary-features">
      {items.map((item) => (
        <li
          key={item.text[0].text}
          className="secondary-features__item"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(item.bgColor?.[0]?.color?.value
            ? { style: { backgroundColor: `#${item.bgColor[0].color.value}` } }
            : {})}
        >
          <Typography data={item.text} customClass="secondary-features__title" />
        </li>
      ))}
    </ul>
  );
};

export default SecondaryFeatures;
