import React, { FC } from 'react';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsProductCarouselNavigation } from './models';

import './ProductCarouselNavigation.scss';

const ProductCarouselNavigation: FC<IPropsProductCarouselNavigation> = ({
  ariaLabelNext,
  ariaLabelPrev,
  navPrevRef,
  navNextRef,
}) => (
  <div data-testid="ProductCarouselNavigation" className="product-carousel-navigation">
    <button
      type="button"
      className="product-carousel-navigation__item product-carousel-navigation__item--prev"
      aria-label={ariaLabelPrev}
      ref={navPrevRef}
    >
      <IconCustom icon="chevron-left-medium" className="icon-prev" />
    </button>
    <button
      type="button"
      className="product-carousel-navigation__item product-carousel-navigation__item--next"
      aria-label={ariaLabelNext}
      ref={navNextRef}
    >
      <IconCustom icon="chevron-left-medium" className="icon-next" />
    </button>
  </div>
);

export default ProductCarouselNavigation;
