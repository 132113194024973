import React, { FC, useEffect, useMemo } from 'react';
import { UmbracoProductCard } from '@shared/types';

import ProductCard from 'components/cards/ProductCard';
import Button from 'components/elements/Button';
import Carousel from 'components/elements/Carousel';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';
import { sortByOrderUrls } from 'utils/sorting';

import { IPropsPopularProducts } from './models';

import './PopularProducts.scss';
import './ShadowPopularProduct.scss';

const PopularProducts: FC<IPropsPopularProducts> = ({
  data: [data],
  globalData,
  popularProductsByLink,
  popularProductsByTag,
}) => {
  const sortProductsByIndex = useMemo(
    () => sortByOrderUrls(popularProductsByLink, data.selectedProductsLinks),
    [data, popularProductsByLink]
  );
  const itemsToRender =
    data?.selectedProductsMode === 'manual' && popularProductsByLink?.length
      ? sortProductsByIndex
      : popularProductsByTag;

  useEffect(() => {
    let intervalCounter = 0;
    const dataLayerCheckInterval = setInterval(() => {
      if (intervalCounter > 20) {
        clearInterval(dataLayerCheckInterval);
      }
      // @ts-ignore
      if (window.dataLayer) {
        // @ts-ignore
        const items = itemsToRender?.map((product: UmbracoProductCard.IStructure) => ({
          item_name: `${product.titleMain}`,
          currency: 'USD',
          item_brand: 'BIOFREEZE',
        }));
        const eventsArray = {
          event: 'view_item_list',
          item_list_name: 'Popular Products',
          ecommerce: {
            items,
          },
        };
        // @ts-ignore)
        window.dataLayer?.push(eventsArray);
        intervalCounter = 0;
        clearInterval(dataLayerCheckInterval);
      }
      intervalCounter += 1;
    }, 300);

    return () => {
      intervalCounter = 0;
    };
  }, []);

  useEffect(() => {
    let intervalCounter = 0;
    const dataLayerCheckInterval = setInterval(() => {
      if(intervalCounter > 20){
        clearInterval(dataLayerCheckInterval);
      }
        // @ts-ignore
        if(!!window.dataLayer) {
          // @ts-ignore
          const items = itemsToRender?.map((product: UmbracoProductCard.IStructure, index) => {
            return {
              'item_name' : `${product.titleMain}`,
              'currency' : 'USD',
              'item_brand' : 'BIOFREEZE',
            }
          })
          const eventsArray = {
            "event" :"view_item_list",
            "item_list_name" : 'Popular Products',
            'ecommerce': {
              items
            }
          };
          // @ts-ignore)
          window.dataLayer?.push(
            eventsArray
          );
          intervalCounter = 0;
          clearInterval(dataLayerCheckInterval);
        }
        intervalCounter = intervalCounter + 1;
    }, 300);
        return () => {
          intervalCounter = 0;
        }
  }, []); 

  return (
    <BaseSection data={data.sectionSettings}>
      <div
        data-testid="PopularProducts"
        id="PopularProductsSection test"
        className="test popular-products"
      >
        {data?.title?.length ? (
          <Typography data={data.title} customClass="popular-products__title" />
        ) : null}
        <Carousel carouselData={data.carouselSettings} globalData={globalData}>
          {itemsToRender?.map((product: UmbracoProductCard.IStructure, index) => (
            <ProductCard
              data={product}
              variant={data?.productCardVariant || 'default'}
              key={product.url}
              cartctabutton={data.cartctabutton}
              gridbuyMikMak={data.gridbuyMikMak}
              grideanvalue={data?.grideanvalue?.length ? data?.grideanvalue[index] : null}
            />
          ))}
        </Carousel>
        {data?.ctaButton?.length ? (
          <div className="popular-products__cta">
            <Button data={data.ctaButton} />
          </div>
        ) : null}
      </div>
    </BaseSection>
  );
};

export default PopularProducts;
