import React, { FC } from 'react';
import { Link } from 'gatsby';

import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsStoreCardsList } from './models';

import './StoreCardsList.scss';

const StoreCardsList: FC<IPropsStoreCardsList> = ({ data }) => (
  <div data-testid="StoreCardsList" className="store-cards-list">
    {data[0].items.map((item) => (
      <div key={item.ariaLabel} className="store-cards-list__item">
        <Link
          to={item.link[0].url}
          className="store-cards-list__link"
          {...(item.link?.[0]?.target === '_blank'
            ? { rel: 'noopener noreferrer', target: '_blank' }
            : {})}
        >
          <GatsbyImage image={item.image} objectFit="contain" />
        </Link>
      </div>
    ))}
  </div>
);

export default StoreCardsList;
