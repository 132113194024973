import React, { FC, lazy, Suspense, useCallback, useState } from 'react';
import classNames from 'classnames';

import Button from 'components/elements/Button';
import StoreCardsList from 'components/elements/StoreCardsList';

import { IPropsBuyPopup } from './models';

import './BuyPopup.scss';

export const BaseModalLoadable = lazy(() => import('components/elements/BaseModal'));
const renderLoader = () => null;
const TRIGGER_BTN_ID = 'buyPopupBtnId';

const BuyPopup: FC<IPropsBuyPopup> = ({ data }) => {
  const [isVisibleBuyProductPopup, setVisibleBuyProductPopup] = useState(false);

  const handleBuyProductPopupVisibility = useCallback(() => {
    setVisibleBuyProductPopup((oldValue: boolean) => !oldValue);
  }, []);

  return data?.length ? (
    <div data-testid="BuyPopup" className="buy-popup">
      <div className="buy-popup__buy-cta-wrapper">
        <Button
          data={data[0].buyCTA}
          clickHandler={handleBuyProductPopupVisibility}
          className={classNames('buy-popup__buy-cta', {
            open: isVisibleBuyProductPopup,
          })}
          id={TRIGGER_BTN_ID}
        />
      </div>
      {isVisibleBuyProductPopup ? (
        <Suspense fallback={renderLoader()}>
          <BaseModalLoadable
            sectionSettings={data[0].sectionSettings}
            title={data[0].title}
            closeBtn={data[0].closeBtn}
            handlePopupVisibility={handleBuyProductPopupVisibility}
            triggerBtnId={TRIGGER_BTN_ID}
          >
            <StoreCardsList data={data[0].list} />
          </BaseModalLoadable>
        </Suspense>
      ) : null}
    </div>
  ) : null;
};

export default BuyPopup;
