import React, { FC } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

import DynamicImage from 'components/helpers/DynamicImage';
import CarouselNavigation from 'components/product/ProductCarousel/ProductCarouselNavigation';

import { IPropsSecondarySlider } from './models';

import './SecondarySlider.scss';

const SecondarySlider: FC<IPropsSecondarySlider> = ({
  items,
  slidesInControls,
  isFirstViewport,
  onSwiperHandler,
  onBeforeInitHandler,
  dir,
  isSmallDevice,
  isShowControls,
  ariaLabelPrev,
  ariaLabelNext,
  navPrevRef,
  navNextRef,
  generalImageAlt,
}) => (
  <div data-testid="SecondarySlider" className="secondary-slider">
    <Swiper
      className="thumb"
      tag="section"
      wrapperTag="ul"
      spaceBetween={isSmallDevice ? 16 : 12}
      slidesPerView={isSmallDevice ? 'auto' : slidesInControls}
      onSwiper={onSwiperHandler}
      onBeforeInit={onBeforeInitHandler}
      navigation={{
        prevEl: navPrevRef.current,
        nextEl: navNextRef.current,
      }}
      pagination={false}
      dir={dir[0]}
      allowSlideNext={isShowControls}
      allowSlidePrev={isShowControls}
      loop={isSmallDevice ? false : slidesInControls < items?.length}
      {...(isSmallDevice
        ? {}
        : { centerInsufficientSlides: true, centeredSlides: false, centeredSlidesBounds: false })}
      watchSlidesProgress
      watchSlidesVisibility
    >
      {items.map((item, index) => {
        if (!item?.image?.desktopImage?.fallbackUrl) {
          return null;
        }

        const mainImage = item.image?.desktopImage.gatsbyImage.childImageSharp?.fluid;
        const aspectRatio = mainImage?.aspectRatio || 0;

        return (
          <SwiperSlide tag="li" data-index={index} key={`${mainImage?.src || index}`}>
            <div
              className={classNames('image-wrapper', {
                horizontal: aspectRatio > 1,
                vertical: aspectRatio < 1,
                'additional-image': index !== 0,
              })}
            >
              <DynamicImage
                image={item.image}
                objectFit="contain"
                isLazyLoading={!isFirstViewport}
                customAlt={generalImageAlt}
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
    {isShowControls ? (
      <CarouselNavigation
        ariaLabelPrev={ariaLabelPrev}
        ariaLabelNext={ariaLabelNext}
        navPrevRef={navPrevRef}
        navNextRef={navNextRef}
      />
    ) : null}
  </div>
);

export default SecondarySlider;
