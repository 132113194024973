import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageIntro from 'components/elements/PageIntro';
import ProductBanner from 'components/product/ProductBanner';
import HowItWorks from 'components/sections/HowItWorks';
import PopularArticles from 'components/sections/PopularArticles';
import PopularProducts from 'components/sections/PopularProducts';
import Reviews from 'components/sections/Reviews';

import { IPropsProductPage } from './models';

import './ProductPage.scss';

const ProductPage: FC<IPropsProductPage> = ({
  data: {
    pageData,
    siteSettings,
    popularArticlesByLink,
    popularArticlesByTag,
    popularProductsByLink,
    popularProductsByTag,
  },
  pageContext,
}) => {
  const {
    seo,
    urls,
    size,
    createDate,
    updateDate,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
    titleMain,
    productDetails,
    productFeatures,
    secondaryFeatures,
    buyCTA,
    buyPopup,
    buyMikMak,
    additionalProductImages,
    defaultProductImage,
    reviews,
    howItWorks,
    popularProducts,
    popularArticles,
    ean,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="ProductPage"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="product-page"
      size={size}
      pageSchemaType="Product"
    >
      <PageIntro
        breadcrumbs={breadcrumbs}
        showBreadcrumbsDesktop={showBreadcrumbsDesktop}
        showBreadcrumbsMobile={showBreadcrumbsMobile}
      />
      <ProductBanner
        title={titleMain}
        productDetails={productDetails}
        productFeatures={productFeatures}
        secondaryFeatures={secondaryFeatures}
        buyCTA={buyCTA}
        buyPopup={buyPopup}
        buyMikMak={buyMikMak}
        additionalProductImages={additionalProductImages}
        defaultProductImage={defaultProductImage}
        ean={ean}
        globalData={{
          ariaLabelNext: siteSettings.ariaLabelNext,
          ariaLabelPrev: siteSettings.ariaLabelPrev,
          dir: siteSettings.dir,
        }}
      />
      {reviews?.length ? (
        <Reviews
          data={reviews}
          globalData={{
            ariaLabelNext: siteSettings.ariaLabelNext,
            ariaLabelPrev: siteSettings.ariaLabelPrev,
            dir: siteSettings.dir,
          }}
        />
      ) : null}
      {howItWorks?.length ? <HowItWorks data={howItWorks} /> : null}
      {popularArticles?.length && (popularArticlesByLink?.nodes || popularArticlesByTag?.nodes) ? (
        <PopularArticles
          popularArticlesByLink={popularArticlesByLink?.nodes}
          popularArticlesByTag={popularArticlesByTag?.nodes}
          data={popularArticles}
          globalData={{
            ariaLabelNext: siteSettings.ariaLabelNext,
            ariaLabelPrev: siteSettings.ariaLabelPrev,
            dir: siteSettings.dir,
          }}
        />
      ) : null}
      {popularProducts?.length && (popularProductsByLink?.nodes || popularProductsByTag?.nodes) ? (
        <PopularProducts
          popularProductsByLink={popularProductsByLink?.nodes}
          popularProductsByTag={popularProductsByTag?.nodes}
          data={popularProducts}
          globalData={{
            ariaLabelNext: siteSettings.ariaLabelNext,
            ariaLabelPrev: siteSettings.ariaLabelPrev,
            dir: siteSettings.dir,
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query (
    $url: String!
    $lang: String!
    $selectedArticlesLinks: [String]
    $selectedArticlesTag: Int
    $selectedProductsLinks: [String]
    $selectedProductsTag: Int
  ) {
    pageData: productPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentProductPage
    }
    siteSettings: siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    popularArticlesByLink: allArticlePage(
      filter: { url: { in: $selectedArticlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    popularArticlesByTag: allArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $selectedArticlesTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticleCard
      }
    }
    popularProductsByLink: allProductPage(
      filter: { url: { in: $selectedProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    popularProductsByTag: allProductPage(
      filter: { tags: { elemMatch: { id: { eq: $selectedProductsTag } } }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
  }
`;

export default ProductPage;
