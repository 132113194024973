import React, { FC } from 'react';
import { UmbracoReviewCard } from '@shared/types';

import ReviewCard from 'components/cards/ReviewCard';
import Carousel from 'components/elements/Carousel';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';

import { IPropsReviews } from './models';

import './Reviews.scss';

const Reviews: FC<IPropsReviews> = ({
  data: [data],
  globalData,
  withoutContainerRightPartDesktop = true,
  withoutContainerRightPartMobile = true,
}) => (
  <BaseSection
    data={data.sectionSettings}
    withoutContainerRightPartDesktop={withoutContainerRightPartDesktop}
    withoutContainerRightPartMobile={withoutContainerRightPartMobile}
  >
    <div data-testid="Reviews" className="reviews">
      {data?.title?.length ? <Typography data={data.title} customClass="reviews__title" /> : null}
      <Carousel carouselData={data.carouselSettings} globalData={globalData}>
        {data.items?.map((review: UmbracoReviewCard.IStructure) => (
          <ReviewCard key={review.name[0].text} data={review} />
        ))}
      </Carousel>
    </div>
  </BaseSection>
);

export default Reviews;
